import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import FarmTech1 from "../assets/images/FarmTech/1.webp";
import FarmTech2 from "../assets/images/FarmTech/2.webp";
import FarmTech3 from "../assets/images/FarmTech/3.webp";
import FarmTech4 from "../assets/images/FarmTech/4.webp";
import FarmTech5 from "../assets/images/FarmTech/5.webp";
import FarmTech6 from "../assets/images/FarmTech/6.webp";
import FarmTech7 from "../assets/images/FarmTech/7.webp";
import FarmTech8 from "../assets/images/FarmTech/8.webp";
import FarmTech9 from "../assets/images/FarmTech/9.webp";
import FarmTech10 from "../assets/images/FarmTech/10.webp";
import FarmTech11 from "../assets/images/FarmTech/11.webp";
import FarmTech12 from "../assets/images/FarmTech/12.webp";
import FarmTech13 from "../assets/images/FarmTech/13.webp";
import FarmTech14 from "../assets/images/FarmTech/14.webp";
import FarmTech15 from "../assets/images/FarmTech/15.webp";
import FarmTech16 from "../assets/images/FarmTech/16.webp";
import FarmTech17 from "../assets/images/FarmTech/17.webp";
import FarmTech18 from "../assets/images/FarmTech/18.webp";
import FarmTech19 from "../assets/images/FarmTech/19.webp";
import FarmTech20 from "../assets/images/FarmTech/20.webp";
import FarmTech21 from "../assets/images/FarmTech/21.webp";
import FarmTech22 from "../assets/images/FarmTech/22.webp";
import FarmTech23 from "../assets/images/FarmTech/23.webp";
import FarmTech24 from "../assets/images/FarmTech/24.webp";
import FarmTech25 from "../assets/images/FarmTech/25.webp";
import FarmTech26 from "../assets/images/FarmTech/26.webp";
import FarmTech27 from "../assets/images/FarmTech/27.webp";
import FarmTech28 from "../assets/images/FarmTech/28.webp";
import FarmTech29 from "../assets/images/FarmTech/29.webp";
import Footer1 from "../assets/images/Home/7.webp";




const FarmTech = () => (
    <Layout>
        <SEO title="FarmTech" />
        <Navbar />
        <div className="page-title-area">
            <img className="imageStyle" src={FarmTech1} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech2} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech3} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech4} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech5} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech6} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech7} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech8} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech9} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech10} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech11} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech12} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech13} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech14} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech15} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech16} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech17} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech18} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech19} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech20} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech21} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech22} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech23} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech24} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech25} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech26} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech27} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech28} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={FarmTech29} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Footer1} alt="image" />
        </div>
    </Layout>
)

export default FarmTech;
